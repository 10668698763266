<template>
  <v-overlay
    :value="show"
  >
    <v-card
      ref="card"
      light
      width="100vw"
      height="100vh"
      class="overflow-y-auto"
    >
      <v-card-title class="flex justify-space-between teal white--text">
        Товары списком
        <v-btn
          min-width="40px"
          width="40px"
          dark
          class="elevation-0 red"
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-container class="relative">
        <ware-compact
          :id="id"
          ref="wareCompact"
          :sort-index="sortIndex"
          @navigate="navigate"
          @close="close"
        >
          <template #navigation>
            <ware-navigation
              :sort-index.sync="sortIndex"
              @beforeChange="updateWare"
              @afterChange="resetWareTriggers"
            />
          </template>
        </ware-compact>
        <create-ware-detail
          v-if="emptyDetails"
          :types="types"
          :ware-id="id"
          @add="addItem"
        />
        <div class="my-10">
          <group-transition>
            <component
              :is="activeComponent"
              :types="types"
              :items="items"
              :ware-id="id"
              :type="type"
              @add="addItem"
              @update="updateItem"
              @delete="deleteItem"
              @fetch="fetchType"
            />
          </group-transition>
        </div>
      </v-container>
    </v-card>
  </v-overlay>
</template>
<script>
import WareCompact from "@/components/zvt/goods/ware-details/ware-compact.vue";
import WareNavigation from "@/components/zvt/goods/ware-details/ware-navigation.vue";
import WareDetailsTypeSelector from "@/components/zvt/goods/ware-details/ware-details-type-selector.vue";
import {mapGetters} from "vuex";
import {disablePageScroll} from "@/events/common";
import GroupTransition from "@/components/zvt/goods/ware-details/group-transition.vue";
import CreateWareDetail from "@/components/zvt/goods/ware-details/create-ware-detail.vue";
import {eventBus} from "@/bus"
import closeOnEscapeMixin from "@/mixins/close-on-escape.mixin";

export default {
  components: {CreateWareDetail, GroupTransition, WareDetailsTypeSelector, WareNavigation, WareCompact},
  mixins:[closeOnEscapeMixin],
  props: {
    show: {
      required: true,
      type: Boolean
    },
    // initial value - prefer this.id
    wareId: {
      required: true,
      type: Number
    },
    wareSortIndex: {
      required: true,
      type: Number
    }
  },
  data() {
    return {
      types: [
        {
          name: "Товары списком",
          value: "ware",
          component: () => import("./ware-details-list.vue"),
          items: [],
          createActionName:"createWareDetails",
          fetchActionName:"getWareDetails"
        },
        {
          name: "Транспорт",
          value: "transport",
          component: () => import("./transport-details-list.vue"),
          items: [],
          createActionName:"createTransportDetails",
          fetchActionName:"getTransportDetails"
        },
        {
          name: "Лесоматериалы",
          value: "woods",
          component: () => import("./woods-details-list.vue"),
          items: [],
          createActionName:"createWoodDetaials",
          fetchActionName:"getWoodDetails"
        },
      ],
      type: null,
      sortIndex: null,
      id: null,
      activeComponent: null
    }
  },
  computed: {
    ...mapGetters({
      selectedWares: 'zvt/getSelectedWares'
    }),
    items(){
      if(!this.type) return []
      return this.types.find(type => type.value === this.type).items
    },
    emptyDetails(){
      return this.types.every((type) => !type.items.length)
    },
  },
  watch: {
    type: {
      handler(value) {
        this.activeComponent = this.types.find(type => type.value === value)?.component ?? null
      },
      immediate: true
    },
    sortIndex() {
      this.updateId()
      this.fetchAllDetails()
    },
    show: {
      handler(value) {
        if (value) {
          this.sortIndex = this.wareSortIndex
          this.id = this.wareId
        }
        disablePageScroll.trigger(value)
      },
      immediate: true
    }
  },
  methods: {
    getItemsByType(typeValue){
      return this.types.find(type => type.value === typeValue).items
    },
    addItem({type, item}){
      if(this.type !== type) this.setType(type)
      const items = this.getItemsByType(type)
      items.push(item)
    },
    updateItem({type, index, item}){
      const items = this.getItemsByType(type)
      items.splice(index, 1, item)
    },
    deleteItem({type, index}){
      const items = this.getItemsByType(type)
      items.splice(index, 1)
    },
    updateTypeItems(typeValue, items) {
      const index = this.types.findIndex(type => type.value === typeValue)
      this.types[index].items = items
    },
    fetchType({value:type, fetchActionName}){
      return this.$store.dispatch(`zvt/${fetchActionName}`, {
        wareId: this.id
      }).then((res) => this.updateTypeItems(type, res.data))
    },
    fetchAllDetails() {
      const promises = this.types.map((type) => this.fetchType(type))
      Promise.all(promises).then(() => {
        this.types.forEach((type) => {
          if (type.items.length) {
            this.setType(type.value)
          }
        })
      })
    },
    setType(type) {
      this.type = type
    },
    navigate(sortIndex) {
      this.sortIndex = sortIndex
      this.updateId()
    },
    updateId() {
      this.id = this.getWareIdBySortIndex()
    },
    getWareIdBySortIndex() {
      const ware = this.selectedWares.find(ware => ware.sort_index === this.sortIndex)
      return ware.id
    },
    updateWare() {
      this.$refs.wareCompact.uploadIfUpdated()
    },
    resetWareTriggers() {
      this.$refs.wareCompact.resetTriggers()
    },
    close() {
      this.$emit('update:show', false)
      eventBus.$emit("updateWareDetails", {items: this.items, wareId: this.id});
    }
  }
}
</script>

<style scoped>
.relative{
  position: relative;
}
</style>
